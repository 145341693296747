@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1609228267804'); /* IE9 */
  src: url('iconfont.eot?t=1609228267804#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALcAAsAAAAABvgAAAKQAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCfgqBZIFTATYCJAMMCwgABCAFhG0HOxsWBsieA+7OYlAg7YQNYHz0exrKePhv7Nt9M/Nt/opa242iXRDLHBKVEkh4iJS2EhKhkb2EP+/ab9y+SYY0zM4i6knlZLvz3dvfF5qHaJaoZEq4ECEUHpFMQ1ky/2uAB4gIPs/l9CbQgcxvZ+d47eVr2drUCzAOKMC9MIqsgBYmaA3Tyxc4EJcJVAsGNF3OrayDjELPCsRbx9JAphBWFJZrC82GjUV8lNRO71MAH6Lnx3/UyJA0Mr3w+s2sCSZ+Df4KnvZO7QeECBDg9gYy9gCFeNaYvgYF46CaTlgDjjUq+NX//DL4xxMKkiCadH4f2EKZxC+dltmC+ie+KIMmCs4BV7RzbGooTWXN76vnv981C3e3HaX3+xb4+NCdBzUN5vpqY2RTqPnJWOs1wvPR9c3xDfiwM/G2n/Ny+28gMk3oL+YB7/Rz8vo6XlyUJ6SFhVifkofR/cWl+JtQGS8s7oFjkXwKC/YLvd/7GvM/2DsqH/5lugX8HPxAbzE805Zgup+pG9hVhEFrfQ2uTiBbnjgroaroobgR39Pt4ypJaBd0DEnLPLK2NbKwe2joOkBT2wmqXfPbu0YxUJQOdlwBhCEfSAY8IBsSk4X9QsOEXzQNBUT1LEYP7NoIJ3fBsMaxifQT5Bg0JDbAc3HhKraqvsbyhojfxKzixJAiyuXcPA4xW2JJpWapnBNEGA3QHLgO+z5FEaMuNrhocx4NSRJpupNo0ADkXGAwDYeZEN0JxGGgQiToz+Uqj1+FWap8GtbRkas3YUyFMzyiEMkDyHlrOKjjUW6pqLGoOI5ACIYKIHNgHObzUUjUPM6FGTiRPSEZGSKZXmSoVdzeHvzAAaj0STlS5Cia7yKfambmNa2HsQcA') format('woff2'),
  url('iconfont.woff?t=1609228267804') format('woff'),
  url('iconfont.ttf?t=1609228267804') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1609228267804#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconupload:before {
  content: "\e610";
}

.icondownload:before {
  content: "\e60f";
}

