/* You can add global styles to this file, and also import other style files */


@primary-text-color: #13263f;
@secondary-text-color: #666666;
@tertiary-text-color: #999999;

@primary-blue-color: #2C7BE5;
@secondary-blue-color: #6E84A3;
@primary-light-blue-color: #6E84A3;

@gray-text-color: #95AAC9;
@primary-dark-color: #2B66CC;
@primary-red-color: #cc432b;
@primary-yellow-color: #cc9e2b;
@light-gray-color: #e8eaf0;
@menu-hover-color: #13263f;
@line-color: #E2EBF6;
@light-line-color: #EDF2F9;

@background-color-light-blue: #F9FBFD;

@blue-base: #1D64C4;
@blue-base-dark: #003781;

@footer-height: 60px;
@menu-width: 250px;

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    width: 100%;
    height: 100%;
}

@background-color: #FAFBFD;
@border-color: #E5EDF7;

//.wrapper {
//    padding: 15px;
//    height: 100%;
//    overflow: auto;
//}

.ant-tabs {
    height: 100%;
    position: static;
}

// 标签页底部
.ant-tabs-content {
    padding-bottom: @footer-height;
}

.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: @footer-height;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 14px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.centre-vh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.search-input {
    margin-left: 10px;
    background-color: transparent;
    border: none;
    outline: none;
}

.search-input:focus {
    background-color: transparent;
    outline: none;
    border: none;
}

.profile-large-title {
    margin: 10px 10px 10px 10px;
    width: 100%;
    display: flex; /*flex弹性布局*/
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.property {
    padding: 4px 0 4px 0;
}

.property-title {
    margin-top: 8px;
    //color: @tertiary-text-color;
    color: @secondary-text-color;
    font-size: 13px;
    display: flex; /*flex弹性布局*/
    justify-content: start;
    align-items: center;
    flex-direction: row;
}

.property-value {
    color: @primary-text-color;
    margin-top: 4px;
    font-size: 13px;
    font-weight: 450;
}

.property-display-value {
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.property-wrapper {
    width: 100%;
    position: relative;
    //justify-content: center;
}

.property-wrapper .clear-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 0;
}

.property-input {
    font-size: 13px;
    font-weight: 450;
    padding: 5px 0px 5px 0px;
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px @line-color solid;
    box-shadow: none;
}

.property-input:focus {
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px @line-color solid;
    box-shadow: none;
}

.property-input-area {
    font-size: 13px;
    font-weight: 450;
    padding: 10px 0px 10px 0px;
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px @line-color solid;
    box-shadow: none;
    resize: none;
}

.property-input-area:focus {
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px @line-color solid;
    box-shadow: none;
    resize: none;
}

.property-bottom-line-blue {
    border-bottom: 1px solid @line-color;
}

.i18n {
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding: 0px 0px 1px 0px;
}

.i18n-edit {
    margin-top: 5px;
    display: flex;
    align-items: center;
    //padding: 5px 0px 5px 0px;
    border-bottom: 1px @line-color solid;
}

.i18n-title {
    float: left;
    font-weight: normal;
    color: @secondary-blue-color;
    margin-bottom: 2px;
}

.i18n-value {
    text-align: right;
    flex: 1;
    height: 32px;
}

.i18n-input {
    margin-left: 5px;
    font-size: 13px;
    font-weight: 450;
    padding: 5px 0px 5px 0px;
    outline: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.i18n-input:focus {
    outline: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.i18n-area {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 0px 0px 1px 0px;
}

.i18n-area-edit {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;    //padding: 5px 0px 5px 0px;
    border-bottom: 1px @line-color solid;
}

.i18n-area-title {
    float: left;
    font-weight: normal;
    color: @secondary-blue-color;
    margin-bottom: 2px;
}

.i18n-area-value {
    text-align: left;
    flex: 1;
    width: 100%;
    color: @primary-text-color;
}

.i18n-area-input {
    margin-left: 5px;
    font-size: 13px;
    font-weight: 450;
    padding: 5px 0px 5px 0px;
    outline: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.i18n-area-input:focus {
    outline: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

nz-tab {
    height: 100%;
}

.footer-button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.footer-button-primary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, @blue-base-dark, @blue-base);

    > a {
        color: white;

        span {
            color: white;
        }

        em {
            color: white;
        }
    }

    > em {
        color: white;
    }

    * {
        color: white;
    }

}

.operations {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button {
    color: @primary-red-color;
}

.tag {
    background-color: #1D64C4;
    color: white;
    border-radius: 4px;
    padding: 4px 6px 4px 6px;
    margin: 2px 2px 2px 2px;
    font-size: 13px;
}

.tag:first-child {
    margin: 2px 2px 2px 0;
}

.tag-sm {
    background-color: #1D64C4;
    color: white;
    border-radius: 4px;
    padding: 4px 6px 4px 6px;
    margin: 2px 2px 2px 2px;
    font-size: 11px;
}

.tag-sm:first-child {
    margin: 2px 2px 2px 0;
}

.tag-outline {
    color: #1D64C4;
    border: 1px solid #1D64C4;
    border-radius: 4px;
    padding: 4px 6px 4px 6px;
    margin: 2px 2px 2px 2px;
    font-size: 13px;
}

.tag-outline:first-child {
    margin: 2px 2px 2px 0;
}

.tag-outline-sm {
    color: #1D64C4;
    border: 1px solid #1D64C4;
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
    margin: 2px 2px 2px 2px;
    font-size: 11px;
}

.tag-outline-sm:first-child {
    margin: 2px 2px 2px 0;
}

.flex-row-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.drawer-container {
    padding: 10px 10px calc(@footer-height + 10px) 10px;
}

.container {
    padding: 10px 0px calc(@footer-height + 10px) 0px;
}

.common-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
}

.drawer-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
}
